import { memoizeWith, path, mergeDeepRight } from "ramda";
import { enumerate, zipWith } from "overline/iterable";
import { defaultDimTextOptions } from "dimtext";
import { exhaust } from "overtype";

import type { Get, EmptyObject, PartialDeep } from "type-fest";
import type { StringPaths } from "overtype";
import type { DimTextOptions, PlainDimension } from "dimtext";
import type { default as ExcelJS, Style } from "exceljs";

type Workbook = ExcelJS.Workbook;

const toPath = memoizeWith((prop) => prop.toString(), (prop: string | number) => {
    if (typeof prop === "number") {
        return [prop];
    }
    return prop.split('.');
});

interface DTypeMap {
    string: string,
    number: number,
    boolean: boolean,
    date: Date,
    hyperlink: {
        text: string,
        hyperlink: string,
        tooltip?: string
    }
}

type Formatter<Row, Cell, DType extends keyof DTypeMap> =
    (value: Cell, options: DataFrameOptions<Row>, row: Row) => DTypeMap[DType] | null;

type Styler<Row, Cell> =
    (value: Cell, options: DataFrameOptions<Row>, row: Row) => Partial<Style> | EmptyObject;

interface BaseColumnDef<Row, DType extends keyof DTypeMap> {
    label: string;
    width?: number;
    // nullable?: boolean;
    type: DType
    displayFilter?: (data: Iterable<Row>) => boolean;
}

type PropColumnDef<Row, Prop extends StringPaths<Row>, DType extends keyof DTypeMap> =
    Get<Row, Prop> extends DTypeMap[DType] | null ? {
        prop: Prop,
        format?: Formatter<Row, Get<Row, Prop>, DType>,
        style?: Styler<Row, Get<Row, Prop>>
    } : {
        prop: Prop,
        format: Formatter<Row, Get<Row, Prop>, DType>,
        style?: Styler<Row, Get<Row, Prop>>
    };

// Distribute over valid DTypes
type PropColumnDefsInner<T, P extends StringPaths<T>> = {
    [D in keyof DTypeMap]: PropColumnDef<T, P, D> & BaseColumnDef<T, D>
}[keyof DTypeMap];

// https://stackoverflow.com/a/68278790
type PropColumnDefs<T> = {
    [P in StringPaths<T>]: PropColumnDefsInner<T, P>
}[StringPaths<T>];

type VirtColumnDef<Row, Getter extends (row: Row) => any, DType extends keyof DTypeMap> =
    ReturnType<Getter> extends DTypeMap[DType] ? {
        value: Getter,
        format?: Formatter<Row, ReturnType<Getter>, DType>,
        style?: Styler<Row, ReturnType<Getter>>
    } : {
        value: Getter,
        format: Formatter<Row, ReturnType<Getter>, DType>,
        style?: Styler<Row, ReturnType<Getter>>
    }

type VirtColumnDefs<T, Getter extends (row: T) => any> = {
    [D in keyof DTypeMap]: VirtColumnDef<T, Getter, D> & BaseColumnDef<T, D>
}[keyof DTypeMap];


function defineVirtualColumn<Row, Getter extends (row: Row) => any>(
    def: VirtColumnDefs<Row, Getter>): VirtColumnDefs<Row, Getter> {
    return def;
}

export type ColumnDef<T> = PropColumnDefs<T> | VirtColumnDefs<T, (row: T) => any>;

type ColumnDefs<T> = Array<ColumnDef<T>>;

export interface DataFrameOptions<T> {
    dimension: DimTextOptions;
    excel: {
        stripeBy: keyof T | ((row: T) => string) | boolean
        stripeStyle: Partial<Style>
    }
};

interface PartialDataFrameOptions<T> {
    dimension?: PartialDeep<DimTextOptions>;
    excel?: {
        stripeBy?: keyof T | ((row: T) => string) | boolean
        stripeStyle?: Partial<Style>
    }
}

const defaultDataFrameOptions: DataFrameOptions<any> = {
    dimension: defaultDimTextOptions,
    excel: {
        stripeBy: false,
        stripeStyle: {
            fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: 'FFDAEEF3' }
            }
        }
    }
};

abstract class ArrayLike<T> {

    abstract [Symbol.iterator](): Generator<T>;

    abstract length: number;

    forEach(fn: (value: T, index: number) => void) {
        for (const [idx, val] of enumerate(this)) {
            fn(val, idx);
        }
    }
}

class LazyArrayLike<T> extends ArrayLike<T> {
    constructor(private generator: () => Generator<T>, public length: number) {
        super();
    }

    *[Symbol.iterator]() {
        yield* this.generator();
    }
}

class DataRow<T> extends ArrayLike<any> {
    options: DataFrameOptions<T>;

    constructor(public data: T, public columns: Array<ColumnDef<T>>, options: PartialDataFrameOptions<T>) {
        super();
        this.options = mergeDeepRight(defaultDataFrameOptions, options) as unknown as DataFrameOptions<T>;
    }

    get length() {
        return this.columns.length;
    }

    *[Symbol.iterator]() {
        yield* this.values();
    }

    *values() {
        for (const column of this.columns) {
            if ("value" in column) {
                yield column.value(this.data);
            } else {
                const pth = toPath(column.prop);
                yield path(pth, this.data) as Get<T, typeof column.prop>;
            }
        }
    }

    *format() {
        yield* zipWith(
            this.values(),
            this.columns,
            (value, column) => {
                // Todo why do we have to narrow this way?
                if ("format" in column && typeof column.format === "function") {
                    return column.format(value, this.options, this.data);
                }
                return value;
            });
    }

    *style() {
        yield* zipWith(
            this.values(),
            this.columns,
            (value, column) => {
                if ("style" in column && typeof column.style === "function") {
                    return column.style(value, this.options, this.data);
                }
                return {} as EmptyObject;
            }
        )
    }
}

class DataFrame<T> extends ArrayLike<DataRow<T>> {
    public options: DataFrameOptions<T>;

    constructor(public data: Array<T>, public columns: Array<ColumnDef<T>>, options: PartialDataFrameOptions<T>) {
        super();
        this.options = mergeDeepRight(defaultDataFrameOptions, options) as unknown as DataFrameOptions<T>;
    }

    // TODO why do we sometimes get better type inference when a value of type T is passed
    // instead of just providing its type?
    static defineColumns<T>(columns: Array<ColumnDefs<T>>): ColumnDefs<T>
    static defineColumns<T>(_: Array<T>, columns: Array<ColumnDefs<T>>): ColumnDefs<T>
    static defineColumns<T>(...args: [Array<ColumnDefs<T>>] | [Array<T>, Array<ColumnDefs<T>>]): ColumnDefs<T> {
        const columns = args.length === 1 ? args[0] : args[1];
        return columns.flat(1);
    }

    get length() {
        return this.data.length;
    }

    *[Symbol.iterator]() {
        for (const row of this.data) {
            yield new DataRow(row, this.columns, this.options);
        }
    }

    format() {
        const numRows = this.data.length;
        const numCols = this.columns.length;
        const rows = this;

        function* formattedRows() {
            for (const row of rows) {
                yield new LazyArrayLike(() => row.format(), numCols);
            }
        }

        return new LazyArrayLike(formattedRows, numRows);
    }

    style() {
        const numRows = this.data.length;
        const numCols = this.columns.length;
        const rows = this;

        function* styledRows() {
            for (const row of rows) {
                yield new LazyArrayLike(() => row.style(), numCols);
            }
        }

        return new LazyArrayLike(styledRows, numRows);
    }

    stripe(): (row: T) => boolean {
        const { stripeBy } = this.options.excel;

        let stripe = false;
        let prev: T | null = null;
        let changed: (a: T, b: T) => boolean;

        switch (typeof stripeBy) {
            case "function":
                changed = (a, b) => stripeBy(a) !== stripeBy(b);
                break;
            case "number":
            case "symbol":
            case "string":
                changed = (a, b) => a[stripeBy] !== b[stripeBy];
                break;
            case "boolean":
                changed = () => stripeBy;
                break;
            default:
                exhaust(stripeBy, 'Unexpected value for stripeBy');
        }

        return function (row: T) {
            const change = prev === null
                ? false
                : changed(prev, row);

            stripe = stripe !== change;
            prev = row;

            return stripe;
        }
    }

    toExcel(wb: Workbook, sheetName: string, name: string, ref: string = "A1") {

        const columns = this.columns.map(({ label }) => ({ name: label }));

        const sheet = wb.getWorksheet(sheetName)
            ?? wb.addWorksheet(sheetName);

        const table = sheet.addTable({
            name,
            ref,
            headerRow: true,
            totalsRow: false,
            style: {
                // https://docs.devexpress.com/OfficeFileAPI/DevExpress.Spreadsheet.BuiltInTableStyleId
                theme: "TableStyleLight20",
                showRowStripes: false,
                showFirstColumn: true
            },
            columns,
            // @ts-ignore
            rows: this.format()
        });

        const styled = this.style();

        const { row: r0, col: c0 } = sheet.getCell(ref).fullAddress;

        const { stripeStyle } = this.options.excel;
        const stripe = this.stripe();

        styled.forEach((rowData, r) => {
            const stripeRow = stripe(this.data[r]);

            rowData.forEach((cellStyle, c) => {
                const cell = sheet.getCell(r0 + r + 1, c0 + c);
                const cellStripeStyle = stripeRow ? stripeStyle : {}
                Object.assign(cell, cellStripeStyle, cellStyle);
            });
        });

        this.columns.forEach(({ width, label }, c) => {
            const column = sheet.getColumn(c0 + c);
            let colWidth: number;

            if (typeof width === "number") {
                colWidth = width;
            } else {
                colWidth = Math.max(8, label.length);
            }

            column.width = colWidth;
        });

        table.commit();

        return {
            sheet,
            table
        };
    }
}

export { DataFrame, DataRow }
