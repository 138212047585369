import type { DimTextOptions } from "dimtext";
import type { PartialDeep } from "type-fest";

interface JobRecordComputed {
  dimtext_options?: PartialDeep<DimTextOptions>;
}

function createJob(orgid: string, props = {}) {
  return {
    id: crypto.randomUUID(),
    name: "New Job",
    location: "",
    organization_id: orgid,
    data: {},
    ...props,
  };
}

export type { JobRecordComputed }
export { createJob };
